import React        from 'react';
import PropTypes    from 'prop-types';
import Translations from "../../utils/Translations";

class NavBarNewIcon extends React.PureComponent {
	render() {
		const className = this.props.isTablet ? " -is-tablet" : "";

		let text = Translations.get('BadgeNew');
		text     = text.toUpperCase();

		return (
			<span className={"navbar-new-icon" + className}>
				{text}
			</span>
		);
	}
}

NavBarNewIcon.propTypes = {
	isTablet: PropTypes.bool,
};

NavBarNewIcon.defaultProps = {
	isTablet: false,
};

export default NavBarNewIcon;
