/*global VXConfig*/
import React     from 'react';
import PropTypes from "prop-types";
import {VXPay}   from '../utils/VXPay';
import Flux      from "../flux/Flux";

const linkClassNameDefault = "navigation__link phoneAccess";
const linkClassNameTablet  = "navbar-tablet__dropdown-link navbar-tablet__dropdown-phone";

class PhoneAccess extends React.PureComponent {

	static onClickButton(e) {
		if (Flux.Guest.isVoicecallActive()) {
			return;
		}

		e.preventDefault();

		if (VXConfig && VXConfig.vxPay && VXConfig.vxPay.externalRefId) {
			VXPay.openVoicecall();
		} else {
			window.location.href = '/?jump=voicecallreload';
		}
	}

	constructor(props) {
		super(props);

		this.state = {
			show: Flux.Browser.getShowPhoneAccess(),
		};

		this.onShowPhoneAccessChange = this.onShowPhoneAccessChange.bind(this);
	}

	componentDidMount() {
		Flux.Browser.addShowPhoneAccessChangeListener(this.onShowPhoneAccessChange);
	}

	componentWillUnmount() {
		Flux.Browser.removeShowPhoneAccessChangeListener(this.onShowPhoneAccessChange);
	}

	onShowPhoneAccessChange(show) {
		this.setState(
			{
				show: show,
			}
		);
	}

	render() {
		if (!this.state.show) {
			return null;
		}


		let linkClassName = linkClassNameDefault + ' phone-red ' + this.props.linkClass;
		let iconClassName = 'icon -icon-phone-full -subnav  navbar-phone__icon ' + this.props.iconClass + (Flux.Browser.isSexole() || VXConfig.language === 'es' ? ' navbar-phone__icon--highlight' : '');

		if (this.props.isTablet) {
			linkClassName = linkClassNameTablet + '  phone-red';
			iconClassName += ' navbar-tablet__dropdown-phone-icon';
		}

		const style = {};
		if (Flux.Guest.isVoicecallActive()) {
			style.color  = 'green';
			style.cursor = 'not-allowed';
		}

		return (
			<a style={style} className={linkClassName} onClick={PhoneAccess.onClickButton} href="#">
				<i className={iconClassName}/><span className="navbar-phone__label">{this.props.label}</span>
			</a>
		);
	}
}

PhoneAccess.propTypes = {
	label:     PropTypes.string,
	isTablet:  PropTypes.bool,
	linkClass: PropTypes.string,
	iconClass: PropTypes.string,
};

PhoneAccess.defaultProps = {
	isTablet:  false,
	linkClass: '',
	iconClass: ''
};

export default PhoneAccess;
